import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import { SharedOrangeButtonHref, Input1 } from "../shared"
import { EModal } from "../course/ScheduleList"
import xSVG from "../../images/icons/x3.svg"
import { SharedModalBackground } from "../shared"
import ScrollLock from "react-scrolllock"
import OutsideClickHandler from "react-outside-click-handler"
import { isMobile } from "react-device-detect"
import { useInput } from "react-hookedup"
import axios from "axios"
import * as EmailValidator from "email-validator"
import { StaticImage } from "gatsby-plugin-image"

EmailValidator.validate("test@email.com")

const EWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  margin: auto;
  max-width: 106em;
  padding: 0 2em;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  position: relative;
`

const ETextsBlock = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  justify-content: flex-end;
  padding-bottom: 2em;

  @media (min-width: 1400px) {
    padding-right: 5em;
  }

  @media (max-width: 700px) {
    filter: drop-shadow(0 0px 2px #fff);
  }
`

const EH1 = styled.h1`
  font-weight: 700;
  color: #1d293f;
  margin: 0;
  font-size: 4em;
`

const EH2 = styled.h2`
  color: #525151;
  font-weight: 400;
  margin: 0;
  font-size: 1.2em;
  margin: 1em 0 1.5em 0;
  line-height: 2;
`

const ETextarea = styled(Input1)`
  resize: none;
`.withComponent("textarea")

const EInput = styled(Input1)`
  font-family: "Rubik", Arial, Helvetica, sans-serif;
`

const ELabel = styled.label`
  font-size: 1.5em;
  background: #fff;
  color: #000;
  position: absolute;
  color: ${(props) => (props.hoisted ? "#8F96A1" : "#111")};
  top: 0.7em;
  transition: all 0.2s;
  left: ${(props) => (props.hoisted ? "1.2em" : "1.2em")};
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  background-color: ${(props) => (props.hoisted ? "#fff" : "transparent")};
  transform: ${(props) =>
    props.hoisted
      ? props.hoistedTransformStr
      : "translateY(0) translateX(-0em) scale(1)"};
`

const Header1 = styled.p`
  font-size: 2em;
  font-weight: 500;
  color: #111;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  margin: 0;
`

const EModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const ECancel = styled(SharedOrangeButtonHref)`
  :hover {
    background-color: #fff;
  }
  :active {
    background-color: #fff;
  }
  background-color: #fff;
  border: transparent;
  color: #fd7114;
  cursor: pointer;
`

const Component = ({ imgs }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const textInput = useInput("")
  const emailInput = useInput("")
  const emailValid = useState(false)

  const handleSendEmail = () => {
    setLoading(true)
    axios
      .post("https://llo2j6i399.execute-api.us-east-1.amazonaws.com/serverless_lambda_stage/email", {
        email: emailInput.value,
        text: textInput.value,
      })
      .then((res) => {
        console.log(res)
        setLoading(false)
        textInput.setValue("")
        emailInput.setValue("")
        setSuccess(true)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (textInput.value.length < 3001) {
      return
    }

    textInput.setValue(textInput.value.substring(0, 3000))
  }, [textInput.value])

  return (
    <>
      <EWrapper>
        <ETextsBlock>
          <EH1>Допоможемо побудувати успішну кар'єру</EH1>
          <EH2>
            Комфортне навчання, підтримка і увага, щоб допомогти Вам на шляху до
            успіху.
          </EH2>

          <SharedOrangeButtonHref
            onClick={() => {
              setModalOpen(true)
            }}
          >
            Безкоштовна консультація
          </SharedOrangeButtonHref>
        </ETextsBlock>

        <StaticImage
          src="../../images/home/topImg.png"
          placeholder="blurred"
          layout="fullWidth"
          objectFit="contain"
          formats={["png"]}
          css={css`
            z-index: -1;

            @media (max-width: 700px) {
              display: none;
            }
          `}
        />
      </EWrapper>

      {modalOpen && (
        <>
          <SharedModalBackground backgroundColor={"#00000054"} />
          {!isMobile && <ScrollLock />}

          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              left: 0;
              z-index: 1100;
            `}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setModalOpen(false)
              }}
            >
              <EModal>
                <>
                  <EModalContent>
                    <div
                      css={css`
                        padding: 20px;
                      `}
                    >
                      {success && (
                        <div
                          css={css`
                            min-height: 300px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                          `}
                        >
                          <h4
                            css={css`
                              padding: 20px 0;
                              margin: 0 0 10px 0;
                              display: flex;
                              border-bottom: 1px solid #e1e2e3;
                              align-items: center;
                              justify-content: space-between;
                            `}
                          >
                            <Header1>We'll contact you soon</Header1>{" "}
                            <img
                              src={xSVG}
                              onClick={() => {
                                setModalOpen(false)
                              }}
                              css={css`
                                height: 14px;
                                cursor: pointer;
                              `}
                            />
                          </h4>

                          <SharedOrangeButtonHref
                            css={css`
                              margin-left: auto;
                              margin-right: auto;
                              margin-top: 100px;
                            `}
                            as="button"
                            onClick={() => {
                              setSuccess(false)
                              setModalOpen(false)
                            }}
                          >
                            Ok
                          </SharedOrangeButtonHref>
                        </div>
                      )}

                      {!success && (
                        <>
                          <h4
                            css={css`
                              padding: 20px 0;
                              margin: 0 0 10px 0;
                              display: flex;
                              border-bottom: 1px solid #e1e2e3;
                              align-items: center;
                              justify-content: space-between;
                            `}
                          >
                            <Header1>Безкоштовна консультація</Header1>{" "}
                            <img
                              src={xSVG}
                              onClick={() => {
                                setModalOpen(false)
                              }}
                              css={css`
                                height: 14px;
                                cursor: pointer;
                              `}
                            />
                          </h4>

                          {/* placeholder="Email" */}

                          <div
                            css={css`
                              margin: 2em 0;
                            `}
                          >
                            <div
                              css={css`
                                position: relative;
                              `}
                            >
                              <ELabel
                                hoisted={emailInput.hasValue}
                                hoistedTransformStr={
                                  "translateY(-1.5em) translateX(-0.5em) scale(0.7)"
                                }
                              >
                                Email
                              </ELabel>
                              <EInput
                                {...emailInput.bindToInput}
                                type="email"
                                hasValue={emailInput.hasValue}
                                notValid={
                                  !EmailValidator.validate(
                                    emailInput.hasValue
                                  ) && emailInput.value.length > 0
                                }
                              />
                            </div>
                          </div>
                          {/* placeholder="Request" */}

                          <div
                            css={css`
                              position: relative;
                            `}
                          >
                            <ELabel
                              hoisted={textInput.hasValue}
                              hoistedTransformStr={
                                "translateY(-1.5em) translateX(-0.66em) scale(0.7)"
                              }
                            >
                              Request
                            </ELabel>

                            <ETextarea
                              rows="6"
                              {...textInput.bindToInput}
                              hasValue={textInput.hasValue}
                            />
                          </div>

                          <div
                            css={css`
                              display: flex;
                            `}
                          >
                            <div
                              css={css`
                                display: grid;
                                grid-template-columns: min-content min-content;
                                grid-column-gap: 10px;
                                margin-left: auto;
                                margin-top: 1em;
                              `}
                            >
                              <ECancel
                                as="button"
                                onClick={() => {
                                  setModalOpen(false)
                                }}
                              >
                                Відмінити
                              </ECancel>

                              <SharedOrangeButtonHref
                                as="button"
                                disabled={
                                  !textInput.hasValue ||
                                  !emailInput.hasValue ||
                                  isLoading
                                }
                                isLoading={isLoading}
                                onClick={handleSendEmail}
                              >
                                Відправити
                              </SharedOrangeButtonHref>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </EModalContent>
                </>
              </EModal>
            </OutsideClickHandler>
          </div>
        </>
      )}
    </>
  )
}

export const TopComponent = (props) => (
  <StaticQuery
    query={graphql`
      query {
        topImg: file(relativePath: { eq: "home/topImg.png" }) {
          childImageSharp {
            fluid(pngCompressionSpeed: 1, quality: 70, maxWidth: 840) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(imgs) => <Component imgs={imgs} {...props} />}
  />
)
