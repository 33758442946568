import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SEO from "../components/seo"
import { css } from "@emotion/react"
import { OurInfo } from "../components/home/OurInfo"
import { OurInfo2 } from "../components/home/OurInfo2"
import { FeedbackCarousel } from "../components/feedbackCarousel/FeedbackCarousel"
import { TopComponent } from "../components/home/TopComponent"
import {
  SharedDivider,
  SharedH3,
  SharedH3DescriptionMini,
} from "../components/shared"

import CoursesTemplate from "../templates/courses"

const Index1 = (props) => {
  return (
    <>
      <Helmet
        title="Apiko Academy"
        meta={[
          {
            name: "description",
            content: "",
          },
        ]}
      >
        <html lang="uk" />
      </Helmet>

      <SEO />

      <Header />

      <SharedDivider data-count={1.5} />

      <TopComponent location={props.location} />

      <SharedDivider data-count={5} />

      <OurInfo />

      <SharedDivider data-count={3.3} />

      <SharedH3
        as="h5"
        css={css`
          color: #111;
        `}
      >
        Наші курси
      </SharedH3>

      <SharedH3DescriptionMini
        css={css`
          color: #8f96a1;
        `}
      >
        Кожен курс ми постійно вдосконалюємо, прислухаємось до ваших відгуків і
        потреб
      </SharedH3DescriptionMini>

      <div
        css={css`
          max-width: 107em;
          padding: 0 2em;
          margin: auto;
          margin-top: 2em;

          > div > div {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @media (max-width: 1200px) {
              grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 640px) {
              grid-template-columns: 1fr;
            }
          }
        `}
      >
        <CoursesTemplate
          firstNCourses={4}
          css={css`
            font-family: Rubik;
          `}
        />
      </div>

      <SharedDivider data-count={2} />

      <OurInfo2 />

      <SharedDivider data-count={3} />

      <SharedH3
        as="h3"
        css={css`
          color: #111;
        `}
      >
        Відгуки студентів
      </SharedH3>

      <FeedbackCarousel />

      <SharedDivider data-count={3} />

      <Footer />
    </>
  )
}


export default Index1
