import React from "react"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { graphql, StaticQuery, navigate } from "gatsby"
import i1 from "../../images/home/i1.svg"
import i2 from "../../images/home/i2.svg"
import i3 from "../../images/home/i3.svg"
import i4 from "../../images/home/i4.svg"
import i5 from "../../images/home/i5.svg"
import heart from "../../images/icons/heart.svg"
import star from "../../images/icons/star.svg"

const EWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  max-width: 107em;
  padding: 0 2em;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  grid-column-gap: 6em;
  grid-row-gap: 6em;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

const LeftBlock = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 5em;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const LeftBlockItem = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 6.2em;
    color: #111;
    font-weight: 700;
    margin: 0;
  }

  p {
    color: #1d293f;
    font-weight: 700;
    font-size: 1.5em;
    margin: 0;
  }
`

const ImgsList = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 8px 0;

  img {
    width: 18px;
    margin-right: 5px;
  }
`

const RightBlock = styled.div`
  h4 {
    color: #1d293f;
    font-size: 3.75em;
    margin: 0;
    margin-bottom: 20px;
  }
`

const MainInfoBlockWrapper = styled.div`
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 2em;
  grid-column-gap: 2em;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const MainInfoBlock = styled.div`
  box-shadow: 0px 0px 8px rgba(14, 86, 124, 0.08);
  border-radius: 10px;
  padding: 1.6em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 24px auto;
  grid-row-gap: 10px;

  img {
    max-height: 24px;
    width: 30px;
  }

  p {
    font-size: 1.01em;
    @media (max-width: 640px) {
      font-size: 14px;
    }
    color: #525151;
    line-height: 1.9;
    margin: 0;
  }
`

export const OurInfo = () => {
  return (
    <EWrapper>
      <LeftBlock>
        <LeftBlockItem>
          <h3>2015 р.</h3>

          <ImgsList>
            <img src={star} />
            <img src={star} />
            <img src={star} />
            <img src={star} />
            <img src={star} />
          </ImgsList>

          <p>З 2015 р. проводимо курси</p>
        </LeftBlockItem>

        <LeftBlockItem>
          <h3>200</h3>
          <ImgsList>
            <img src={heart} />
            <img src={heart} />
            <img src={heart} />
            <img src={heart} />
            <img src={heart} />
          </ImgsList>
          <p>учасників курсів</p>
        </LeftBlockItem>
      </LeftBlock>

      <RightBlock>
        <h4>Що вам потрібно знати про нас</h4>

        <MainInfoBlockWrapper>
          <MainInfoBlock>
            <img src={i1} />
            <p>
              Ми вже провели 8 навчальних інтенсивів. Кожен тривалістю 2-3
              місяці по 20-30 учасників.
            </p>
          </MainInfoBlock>

          <MainInfoBlock>
            <img src={i3} />
            <p>
              Після успішного закінчення курсів ваша кандидатура буде
              розглядатися на працевлаштування у компанії Апіко.
            </p>
          </MainInfoBlock>

          <MainInfoBlock>
            <img src={i4} />
            <p>
              У разі успішного завершення курсу ви отримаєте сертифікат, який
              можете пред'явити потенційному роботодавцеві.
            </p>
          </MainInfoBlock>

          <MainInfoBlock>
            <img src={i2} />
            <p>
              Працівники компанії Апіко щодня працюють із технологіями, про які
              ми розповідаємо на курсах
            </p>
          </MainInfoBlock>
        </MainInfoBlockWrapper>
      </RightBlock>
    </EWrapper>
  )
}
