import React from "react"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { graphql, StaticQuery, navigate } from "gatsby"
import { SharedH3, SharedH3DescriptionMini } from "../../components/shared"
import { StaticImage } from "gatsby-plugin-image"

const EWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: "Rubik", Arial, Helvetica, sans-serif;

  @media (max-width: 1250px) {
    display: flex;
  }
`

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 51em;
  margin-left: auto;
  padding-right: 7em;
  justify-content: center;

  @media (max-width: 1250px) {
    padding: 0 2em;
    margin: 2em auto auto auto;
  }
`

const EFeatures = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 18px;
  grid-row-gap: 26px;
  margin-top: 1.8em;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain !important;
    pointer-events: none;
  }
`

const ThisH3Description = styled(SharedH3DescriptionMini)`
  color: #525151;
  margin-top: 0.8em;
  line-height: 1.8;
  margin: 0;
  padding: 0;
  text-align: initial;

  a {
    color: #3489e4;
    text-decoration: none;
  }
`

const FeaturesImg = styled(Img)`
  height: 24px !important;
  width: 30px !important;
  object-fit: contain !important;
`

const Component = ({ imgs }) => {
  return (
    <EWrapper>
      <LeftBlock>
        <SharedH3
          as="h6"
          css={css`
            @media (min-width: 640px) {
              margin: 0;
              padding: 0;
              text-align: initial;
            }
          `}
        >
          Наближеність до реальної роботи
        </SharedH3>

        <SharedH3DescriptionMini
          css={css`
            color: #525151;
            margin-top: 0.8em;
            line-height: 1.8;

            @media (min-width: 640px) {
              margin: 0;
              padding: 0;
              text-align: initial;
            }
          `}
        >
          Ми вирішили наші курси максимально наблизити до реальної роботи, тому
          весь інтенсив буде проходити:
        </SharedH3DescriptionMini>

        <EFeatures>
          <a href="https://gitlab.com" target="_blank" rel="noreferrer">
            <FeaturesImg
              fixed={imgs.i1.childImageSharp.fixed}
              durationFadeIn={200}
            />
          </a>
          <ThisH3Description>
            <a href="https://gitlab.com" target="_blank" rel="noreferrer">
              Gitlab.com
            </a>{" "}
            для репозиторію та задач
          </ThisH3Description>

          <a href="https://figma.com" target="_blank" rel="noreferrer">
            <FeaturesImg
              fixed={imgs.i2.childImageSharp.fixed}
              durationFadeIn={200}
            />
          </a>
          <ThisH3Description>
            <a href="https://figma.com" target="_blank" rel="noreferrer">
              Figma.com
            </a>{" "}
            робота з дизайнами
          </ThisH3Description>

          <a href="https://slack.com" target="_blank" rel="noreferrer">
            <FeaturesImg
              fixed={imgs.i3.childImageSharp.fixed}
              durationFadeIn={200}
            />
          </a>
          <ThisH3Description>
            <a href="https://slack.com" target="_blank" rel="noreferrer">
              Slack.com
            </a>{" "}
            для спілкування з командою
          </ThisH3Description>

          <a href="https://zoom.us" target="_blank" rel="noreferrer">
            <FeaturesImg
              fixed={imgs.i4.childImageSharp.fixed}
              durationFadeIn={200}
            />
          </a>
          <ThisH3Description>
            <a href="https://zoom.us" target="_blank" rel="noreferrer">
              Zoom.us
            </a>{" "}
            щотижневе групове заняття із ментором
          </ThisH3Description>

          <a href="https://www.udemy.com/" target="_blank" rel="noreferrer">
            <FeaturesImg
              fixed={imgs.i5.childImageSharp.fixed}
              durationFadeIn={200}
            />
          </a>
          <ThisH3Description>
            <a href="https://www.udemy.com/" target="_blank" rel="noreferrer">
              udemy
            </a>{" "}
            доступні онлайн матеріали
          </ThisH3Description>
        </EFeatures>
      </LeftBlock>

      <StaticImage
        src="../../images/home/github.png"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="contain"
        formats={["png"]}
        css={css`
          pointer-events: none;
          @media (max-width: 1250px) {
            display: none;
          }
        `}
      />
    </EWrapper>
  )
}

export const OurInfo2 = () => (
  <StaticQuery
    query={graphql`
      query nonameQuery99 {
        i1: file(relativePath: { eq: "home/i1.png" }) {
          childImageSharp {
            fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        i2: file(relativePath: { eq: "home/i2.png" }) {
          childImageSharp {
            fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        i3: file(relativePath: { eq: "home/i3.png" }) {
          childImageSharp {
            fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        i4: file(relativePath: { eq: "home/i4.png" }) {
          childImageSharp {
            fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        i5: file(relativePath: { eq: "home/i5.png" }) {
          childImageSharp {
            fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(imgs) => <Component imgs={imgs} />}
  />
)
